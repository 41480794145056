import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { useDataLoadContext } from 'contexts/data-load-context';

const LazyContactUsPage = loadable(() => import('suspended-page/contact-us'));

const ContactUsPage = () => {
  const [loading, setLoading] = useState(true);

  const { recaptchaScriptLoaded } = useDataLoadContext();

  useEffect(() => {
    recaptchaScriptLoaded()
      .then((result) => setLoading(!result));
    // eslint-disable-next-line
  }, []);

  return (<LazyContactUsPage loading={loading}/>);
};

export default ContactUsPage;
